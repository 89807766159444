import React from 'react';
import PixelButton from './PixelButton';

const projects = [
  { name: 'Fallout 76', url: 'https://fallout76.snorl.ax/' },
  { name: 'GitHub', url: 'https://github.com/ImSoHM02/' },
];

function ProjectLinks() {
  return (
    <div className="content-area">
      <div className="project-links">
        {projects.map((project, index) => (
          <PixelButton key={index} name={project.name} url={project.url} />
        ))}
      </div>
    </div>
  );
}

export default ProjectLinks;