import React from 'react';
import './App.css';
import ProjectLinks from './components/ProjectLinks';

function App() {
  return (
    <div className="App">
      <div className="snorlax-background">
        <ProjectLinks />
      </div>
    </div>
  );
}

export default App;