import React from 'react';

function PixelButton({ name, url }) {
  return (
    <a href={url} className="pixel-button">
      {name}
    </a>
  );
}

export default PixelButton;